
<template>
  <div class="h-min-content max-w-full">
    <div class="px-4 py-6 bg-white rounded-2xl shadow-lg mx-4">
      <h2 class="text-left text-1xl mx-2 text-gray-600 pb-6"><b>¿Cómo desea radicar la nueva inconformidad?</b></h2>
      <div class="max-w-4xl mx-auto grid grid-cols-1 lg:grid-cols-2 justify-center items-start gap-8">
        <div class="w-full max-w-md mx-auto px-0 py-6 bg-white rounded-2xl shadow-lg">
          <div class="h-14 w-14 m-auto flex justify-center items-center rounded-full bg-white border-2">
              <img src="@/assets/icons/file.svg" alt="">
          </div>
          <h2 class="text-1xl text-blue-dark py-2"><b>Radicar nueva inconformidad con una persona registrada</b></h2>
          <div class="border border-blue-dark"/>
          <p class="text-center text-xs text-gray-400 mx-12 pt-4">Suministre la información relacionada con la inconformidad frente a un producto o servicio del sistema financiero.</p>
          <BaseButton type="button" class="mt-6 mx-auto w-3/4" label="DILIGENCIAR FORMULARIO" @click.native="registeredCustomer()" />
        </div>
        <div class="w-full max-w-md mx-auto  px-0 py-6 bg-white rounded-2xl shadow-lg">
          <div class="h-14 w-14 m-auto flex justify-center items-center rounded-full bg-white border-2">
              <img src="@/assets/icons/file.svg" alt="">
          </div>
          <h2 class="text-1xl text-blue-dark py-2"><b>Radicar nueva inconformidad con una persona sin registrar</b></h2>
          <div class="border border-blue-dark"/>
          <p class="text-center text-xs text-gray-400 mx-12 pt-4">Registre el nuevo usuario y suministre la información relacionada con la inconformidad frente a un producto o servicio del sistema financiero.</p>
          <BaseButton type="button" class="mt-6 mx-auto w-3/4" label="DILIGENCIAR FORMULARIO" @click.native="nonRegisteredCustomer()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    registeredCustomer () {
      this.$router.push({ name: 'ComplaintsNewRegisteredCustomer' });
    },
    nonRegisteredCustomer () {
      this.$router.push({ name: 'ComplaintsNewCustomerRegistration' });
    }
  }
}
</script>